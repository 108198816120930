.centerColumn {
  border-right: solid 1px;
}

.mainContainer {
  margin-top: 75px;
}

.socialLinks {
  margin-top: 75px;
  background-color: #212529;
  color: white;
  text-align: center;
  padding: 20px;
}

.socialLink {
  margin-right: 10px;
  margin-left: 10px;
}
